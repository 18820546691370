// 【转换】获取路径"?"后面的参数
export function getUrlParams() {
  let path = location.search;
  let params = new Object();
  if (path.indexOf("?") != -1) {
    path = path.slice(1).split("&");
    for (let i of path) {
      params[i.split("=")[0]] = decodeURI(i.split("=")[1]);
    }
  }
  return params;
};

// 时间格式转换优化版
export function formatterDate(time, format) { //time:时间撮；fmt:格式
  let date = new Date(time);
  if (time == null || time == "") {
    return "暂无日期";
  }
  if (!format) {
    format = 'yyyy-MM-dd HH:mm:ss';
  }
  let o = {
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "M+": date.getMonth() + 1, //月
    "d+": date.getDate(), //日
    "H+": date.getHours(), //时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "S": date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (let k in o)
    if (new RegExp("(" + k + ")").test(format))
      format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return format;
}

/**
 * 时间格式转换器
 * @param {*} time 时间撮
 * @param {*} format 格式
 * @returns 
 */
export function formatDate(time, format) {
  if (time == null || time == "") {
    return "暂无日期";
  }
  switch (Number(format)) {
    case 1:
      format = 'yyyy-MM-dd HH:mm:ss';
      break;
    case 2:
      format = 'yyyy/MM/dd';
      break;
    case 3:
      format = 'yyyy/MM/dd HH:mm:ss';
      break;
    case 4:
      format = 'yyyyMMdd';
      break;
    case 5:
      format = 'yyyy-MM-dd HH:mm';
      break;
    default:
      format = 'yyyy-MM-dd';
  }
  let d = new Date(time);
  let o = {
    "q+": Math.floor((d.getMonth() + 3) / 3), //季度
    "M+": d.getMonth() + 1, //月
    "d+": d.getDate(), //日
    "H+": d.getHours(), //时
    "m+": d.getMinutes(), //分
    "s+": d.getSeconds(), //秒
    "S": d.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(format))
    format = format.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (let i in o)
    if (new RegExp("(" + i + ")").test(format))
      format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[i]) : (("00" + o[i]).substr(("" + o[i]).length)));
  return format;
}


export function changeDate(time, format) {
  if (time == null || time == "") {
    return "-";
  }
  switch (Number(format)) {
    case 1:
      format = 'yyyy-MM-dd HH:mm:ss';
      break;
    case 2:
      format = 'yyyy-MM';
      break;
    default:
      format = 'yyyy-MM-dd';
  }
  var d = new Date(time);
  var o = {
    "q+": Math.floor((d.getMonth() + 3) / 3), //季度
    "M+": d.getMonth() + 1, //月
    "d+": d.getDate(), //日
    "H+": d.getHours(), //时
    "m+": d.getMinutes(), //分
    "s+": d.getSeconds(), //秒
    "S": d.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(format))
    format = format.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var i in o)
    if (new RegExp("(" + i + ")").test(format))
      format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[i]) : (("00" + o[i]).substr(("" + o[i]).length)));
  return format;
}



//对象转选项
export function objToOpt(obj) {
  let options = []
  for (let i in obj) {
    options = [...options, {
      value: i,
      text: obj[i]
    }]
  }
  return options
};

export function monthLater(time, format) {
  if (time == null || time == "") {
    return "暂无日期";
  }
  switch (Number(format)) {
    case 1:
      format = 'yyyy-MM-dd HH:mm:ss';
      break;
    default:
      format = 'yyyy-MM-dd';
  }
  let d = new Date(time);
  let o = {
    "q+": Math.floor((d.getMonth() + 3) / 3), //季度
    "M+": d.getMonth() + 2, //月
    "d+": d.getDate(), //日
    "H+": d.getHours(), //时
    "m+": d.getMinutes(), //分
    "s+": d.getSeconds(), //秒
    "S": d.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(format))
    format = format.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (let i in o)
    if (new RegExp("(" + i + ")").test(format))
      format = format.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[i]) : (("00" + o[i]).substr(("" + o[i]).length)));
  return format;
}

//获取当月最后一天的日期
export function getLastDay() {
  var year = new Date().getFullYear(); //获取年份
  var month = new Date().getMonth() + 1; //获取月份
  var lastDate = new Date(year, month, 0).getDate(); //获取当月最后一日
  month = month < 10 ? '0' + month : month; //月份补 0
  return [year, month, lastDate].join("-")
}


// 【转换】文件列表处理
export function changeFileList(list) {
  function readFileType(url) {
    url = url.split('.')
    let type = url[url.length - 1]
    if (type == "jpg" || type == "gif" || type == "png" || type == "bmp" || type == "jpeg") {
      return true
    } else {
      return false
    }
  };
  let obj = {
    fileList: [],
    imgList: [],
    imgViewList: []
  };
  if (list) {
    for (let i of list) {
      if (readFileType(i.fileUrl)) {
        obj.imgList.push(i);
        obj.imgViewList.push(i.fileUrl);
      } else {
        obj.fileList.push(i);
      }
    };
  };
  return obj
};